import styled from 'styled-components';
import { Button, Typography, styled as muiStyled } from '@mui/material';
import { Background as Background4, MaxContainer } from '../../styles';

import img_speakers from './4756_SE_Budget Decoded Creative Speakers 1.png';
import imgBg from './assets/ask-me-anything-bg.png';

const AskMeAnything = () => {
  return (
    <Background>
      <Container>
        <OvalText>StockEdge Club Presents</OvalText>
        <Heading>Get all your Stock Queries Answered</Heading>
        <SubHeading>
          Experience the power of StockEdge Club for 7 days
        </SubHeading>
        <Description>
          For the first time ever, we are unlocking some of our StockEdge Club
          features for free. Here you can ask your trading and investment
          queries directly with experts over a 90 minute session everyday for 7
          days.
        </Description>
        <RegisterButton href="#event-schedule">
          Register now for Free
        </RegisterButton>
        <DateText>Date: 18 to 23 December, 2023</DateText>
        <Image src={img_speakers} alt="" />
      </Container>
      <GradiantLine />
    </Background>
  );
};

export default AskMeAnything;

const GradiantLine = muiStyled('div')({
  display: 'flex',
  width: '100%',
  height: '3px',
  background:
    'linear-gradient(90deg, rgba(248, 195, 2, 0.00) 0%, #FFDF69 50.34%, rgba(248, 195, 2, 0.00) 100%)'
});

const OvalText = muiStyled(Typography)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'min(270px, 100%)',
  height: '41px',
  borderRadius: '100px',
  border: '1px solid rgba(173, 151, 32, 0.20)',
  background:
    'linear-gradient(180deg, rgba(173, 151, 32, 0.15) 0%, rgba(0, 0, 0, 0.00) 136.59%)',
  color: '#F8C302',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: 'normal',
  marginBottom: '32px',
  marginTop: '50px'
});

const DateText = muiStyled(Typography)({
  color: '#FFF',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: 'normal'
});

const RegisterButton = muiStyled(Button)({
  width: 'min(260px, 100%)',
  height: '56px',
  borderRadius: '5px',
  background: '#F8C302',
  boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.25)',
  color: '#000',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: 'normal',
  textTransform: 'none',
  marginTop: '32px',
  marginBottom: '16px',
  '&:hover': {
    background: '#F8C302',
    color: '#000'
  },
  '@media (min-width: 540px)': {
    fontSize: '16px',
    marginTop: '40px',
    marginBottom: '20px'
  },
  '@media (min-width: 640px)': {
    fontSize: '18px',
    marginTop: '60px',
    marginBottom: '25px'
  }
});

const Heading = muiStyled(Typography)({
  color: '#FFF',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '36px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '45px',
  '@media (min-width: 425px)': {
    fontSize: '42px',
    lineHeight: '60px'
  },
  '@media (min-width: 540px)': {
    fontSize: '45px',
    lineHeight: '65px'
  },
  '@media (min-width: 640px)': {
    fontSize: '50px',
    lineHeight: '75px'
  }
});

const SubHeading = muiStyled(Typography)({
  color: '#F8C302',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '30px',
  marginTop: '10px',
  '@media (min-width: 425px)': {
    fontSize: '22px',
    lineHeight: '30px'
  },
  '@media (min-width: 540px)': {
    fontSize: '25px',
    lineHeight: '36px'
  },
  '@media (min-width: 640px)': {
    fontSize: '30px',
    lineHeight: '50px'
  }
});

const Description = muiStyled(Typography)({
  maxWidth: '1012px',
  color: '#989898',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '25px',
  marginTop: '16px',
  '@media (min-width: 540px)': {
    fontSize: '16px',
    lineHeight: '25px'
  },
  '@media (min-width: 640px)': {
    fontSize: '18px',
    lineHeight: '30px'
  }
});

const Image = muiStyled('img')({
  width: 'min(100%, 1360px)',
  objectFit: 'contain',
  objectPosition: 'bottom'
});

const Container = styled(MaxContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0 0 0;
`;

const Background = styled(Background4)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${imgBg});
`;
