import Dialog from 'rc-dialog';
import RequestACallbackForm from './RequestACallbackForm';
import RequestACallbackSuccessMessage from './RequestACallbackSuccessMessage';
import 'rc-dialog/assets/index.css';

const RequestACallbackModal = ({ modal, setModal }: any) => {
  const closeModal = () => setModal(undefined);
  return (
    <Dialog
      title="Request a Callback"
      onClose={closeModal}
      visible={Boolean(modal)}
      maskAnimation="fade"
      focusTriggerAfterClose={false}
      className={modal === 'MESSAGE' ? 'message-dialog' : ''}
    >
      {modal === 'MESSAGE' && (
        <RequestACallbackSuccessMessage closeModal={closeModal} />
      )}
      {modal === 'FORM' && <RequestACallbackForm setModal={setModal} />}
    </Dialog>
  );
};

export default RequestACallbackModal;
