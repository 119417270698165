import { Fragment, useState } from 'react';
import RequestACallbackContext from './RequestACallbackContext';
import RequestACallbackModal from './RequestACallbackModal';

const RequestACallbackProvider = (props: any) => {
  const [modal, setModal] = useState<any>(undefined);
  const openModal = () => setModal('FORM');

  return (
    <Fragment>
      <RequestACallbackContext.Provider value={{ openModal }} {...props} />
      <RequestACallbackModal modal={modal} setModal={setModal} />
    </Fragment>
  );
};

export default RequestACallbackProvider;
