import ReactPlayer from 'react-player/file';
import styled from 'styled-components';
import { Background4, MaxContainer } from '../../styles';

const BecomeStockEdgeExpert = () => {
  return (
    <Background>
      <Container>
        <TextContainer>
          <Title>
            <span>Become a</span>
            {'\n'}
            StockEdge Expert
          </Title>
          <SubTitle>
            If you have an expertise in any sector/industry, join hands to{' '}
            {'\n'}create value and empower our community.
          </SubTitle>
          <ButtonContainer>
            <LinkButton
              target="_blank"
              href="https://forms.gle/pAV25h5axozi77FX9"
            >
              Apply Now
            </LinkButton>
          </ButtonContainer>
        </TextContainer>
        <VideoContainer>
          <ReactPlayer
            height={320}
            width={640}
            url="https://d2fgrgclavu78s.cloudfront.net/seclub/videos/sector-expert.mp4"
            light="https://d2fgrgclavu78s.cloudfront.net/seclub/images/sector-expert-placeholder.png"
            playIcon={undefined}
            controls
            playing
            loop
          />
        </VideoContainer>
      </Container>
    </Background>
  );
};

export default BecomeStockEdgeExpert;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 32px;
  margin-bottom: 32px;
`;

const LinkButton = styled.a`
  box-sizing: border-box;
  background: #fdc00a;
  color: #000;
  height: 48px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 8px 12px;
  border-radius: 24px;
  min-width: 150px;
  font-size: 14px;
  font-weight: 500;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const VideoContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  & > div {
    border: 5px solid #fdc00a;
    width: 320px !important;
    height: 174px !important;
  }
  .react-player__play-icon {
    display: none;
  }
  @media (min-width: 375px) {
    & > div {
      width: 375px !important;
      height: 204px !important;
    }
  }
  @media (min-width: 425px) {
    & > div {
      width: 425px !important;
      height: 234px !important;
    }
  }
  @media (min-width: 1025px) {
    & > div {
      width: 640px !important;
      height: 306px !important;
    }
  }
`;

const Title = styled.h1`
  color: #fdc00a;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.75rem;
  white-space: pre-line;
  text-transform: uppercase;
  white-space: pre-line;
  text-align: center;
  margin-bottom: 32px;
  span {
    color: #ffffff;
  }
`;

const SubTitle = styled.h4`
  color: #ffffff;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.25rem;
  text-align: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled(MaxContainer)`
  display: flex;
  flex-direction: column;
  padding: 100px 0 50px 0;
  @media (min-width: 540px) {
    ${LinkButton} {
      min-width: 200px;
    }
  }
  @media (min-width: 960px) {
    flex-direction: row;
    ${TextContainer} {
      align-items: flex-start;
    }
    ${Title} {
      text-align: left;
    }
    ${VideoContainer} {
      width: 50%;
      display: inline-flex;
      margin: 0 0 0 20px;
    }
    ${TextContainer} {
      width: 50%;
      display: inline-flex;
      margin: 0 20px 0 0;
      align-items: flex-start;
    }
    ${Title} {
      font-size: 2.5rem;
      line-height: 3.2rem;
      margin: 0;
      margin-bottom: 32px;
    }
    ${SubTitle} {
      text-align: left;
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin: 10px 0 20px;
      white-space: pre-line;
    }
  }
  @media (min-width: 1025px) {
    ${Title} {
      font-size: 3rem;
      line-height: 4rem;
      margin: 0;
    }
    ${SubTitle} {
      text-align: left;
      font-size: 1.5rem;
      line-height: 2.5rem;
      margin: 10px 0 20px;
    }
  }
`;

const Background = styled(Background4)`
  display: flex;
  align-items: center;
`;
