import { Fragment, Suspense, lazy } from 'react';
import LearningAndEarnings from '../sections/LearningAndEarnings';

const HostOfFeatures = lazy(() => import('../sections/HostOfFeatures'));
const SubscriptionNew = lazy(() => import('../sections/SubscriptionNew'));
const FAQ = lazy(() => import('../sections/FAQ'));
const StockEdgeClubExclusive = lazy(
  () => import('../components/StockEdgeClubExclusive')
);
const TopMarketsExparts = lazy(() => import('../sections/TopMarketExpertsNew'));
const TopAnalysts = lazy(() => import('../sections/TopAnalysts'));
const ClubMemberThoughts = lazy(() => import('../sections/ClubMemberThoughts'));
const Footer = lazy(() => import('../components/Footer'));

const Home = () => (
  <Fragment>
    <LearningAndEarnings />
    <Suspense fallback={<Fragment />}>
      <StockEdgeClubExclusive />
    </Suspense>
    <Suspense fallback={<Fragment />}>
      <TopAnalysts />
    </Suspense>
    <Suspense fallback={<Fragment />}>
      <TopMarketsExparts />
    </Suspense>
    <Suspense fallback={<Fragment />}>
      <HostOfFeatures />
    </Suspense>
    <Suspense fallback={<Fragment />}>
      <SubscriptionNew />
    </Suspense>
    <Suspense fallback={<Fragment />}>
      <ClubMemberThoughts />
    </Suspense>
    <Suspense fallback={<Fragment />}>
      <FAQ />
    </Suspense>
    <Suspense fallback={<Fragment />}>
      <Footer />
    </Suspense>
  </Fragment>
);

export default Home;
