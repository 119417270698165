import styled from 'styled-components';

const RequestACallbackSuccessMessage = ({ closeModal }: any) => (
  <Body>
    <br />
    <p>
      Request submitted successfully.
      <br />
      You can also call <a href="tel:9830994463">9830994463</a> if you need any
      immediate assistance.
    </p>
    <br />
    <Center>
      <Button onClick={closeModal}>Close</Button>
    </Center>
  </Body>
);

export default RequestACallbackSuccessMessage;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  small {
    color: #819ba6;
    font-size: 12px;
    white-space: break-spaces;
    margin-bottom: 15px;
    line-height: 16px;
    text-align: center;
    a {
      color: #007bff;
      text-decoration: none;
      background-color: transparent;
    }
  }
`;

const Button = styled.button`
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  &:not(:disabled) {
    cursor: pointer;
  }
`;

const Body = styled.div`
  padding: 16px;
  p {
    font-size: 0.8rem;
    margin: 0;
    line-height: 1.25rem;
    margin-bottom: 5px;
    color: #819ba6;
  }
`;
