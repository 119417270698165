import { useState, Fragment } from 'react';
import Dialog from 'rc-dialog';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import styled from 'styled-components';
import axios from 'axios';
import * as Yup from 'yup';
import { load } from 'recaptcha-v3';
import { Oval } from 'react-loading-icons';
import { RECAPTHA_SITE_KEY, API_BASE_URL } from '../constants';
import { useGetCountries } from '../hooks';
import RequestACallbackSuccessMessage from './RequestACallbackSuccessMessage';

const RequestACallbackForm = () => {
  const countries = useGetCountries();
  const [modal, setModal] = useState<any>(false);

  const closeModal = () => setModal(false);

  return (
    <Fragment>
      <Dialog
        title="Request a Callback"
        onClose={closeModal}
        visible={Boolean(modal)}
        maskAnimation="fade"
        focusTriggerAfterClose={false}
        className={modal === 'MESSAGE' ? 'message-dialog' : ''}
      >
        <RequestACallbackSuccessMessage closeModal={closeModal} />
      </Dialog>
      <Body>
        <Center>
          <p>Want to try StockEdge Club ?</p>
        </Center>

        <Formik
          enableReinitialize
          initialValues={{
            FirstName: '',
            LastName: '',
            Email: '',
            PhoneDialInCode: '+91',
            Phone: '',
            Message: ''
          }}
          onSubmit={async (values, action) => {
            try {
              const recaptcha = await load(RECAPTHA_SITE_KEY);
              const RecaptchaToken = await recaptcha.execute('submit');
              Object.assign(values, { Source: 'demo-lead' });
              await axios.post(`${API_BASE_URL}/validateCaptcha`, {
                ...values,
                RecaptchaToken
              });
              setModal(true);
              action.resetForm();
            } catch (e) {
              console.error(e);
            } finally {
              action.setSubmitting(false);
            }
          }}
          validationSchema={ValidationSchema}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            values,
            isSubmitting
          }) => (
            <Form onSubmit={handleSubmit} noValidate>
              {isSubmitting && <Loading />}
              <Flex>
                <FormGroup className="mr-5">
                  <Field name="FirstName" placeholder="First Name" />
                  <ErrorMessage name="FirstName" component="span" />
                </FormGroup>
                <FormGroup className="ml-5">
                  <Field name="LastName" placeholder="Last Name" />
                  <ErrorMessage name="LastName" component="span" />
                </FormGroup>
              </Flex>
              <Flex>
                <FormGroup>
                  <Field name="Email" placeholder="Email" />
                  <ErrorMessage name="Email" component="span" />
                </FormGroup>
              </Flex>
              <Flex>
                <FormGroup className="mr-5" style={{ maxWidth: 100 }}>
                  {/* <Label>Country</Label> */}
                  <select
                    name="PhoneDialInCode"
                    value={values.PhoneDialInCode}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    {countries?.map((item: any) => (
                      <option key={item.ID} value={item.DialInCode}>
                        ({item.DialInCode})
                      </option>
                    ))}
                  </select>
                </FormGroup>
                <FormGroup className="ml-5">
                  {/* <Label>Phone*</Label> */}
                  <input
                    name="Phone"
                    placeholder="Phone"
                    value={values.Phone}
                    onChange={(e) => {
                      e.preventDefault();
                      const { value } = e.target;
                      setFieldValue('Phone', value.replace(/\D/g, ''));
                    }}
                    maxLength={10}
                    minLength={10}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage name="Phone" component="span" />
                </FormGroup>
              </Flex>
              <Flex>
                <FormGroup>
                  <textarea
                    name="Message"
                    placeholder="Message"
                    value={values.Message}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    rows={3}
                  />
                  <ErrorMessage name="Message" component="span" />
                </FormGroup>
              </Flex>
              <Center>
                <Button type="submit">REQUEST A CALLBACK</Button>
              </Center>
            </Form>
          )}
        </Formik>
      </Body>
    </Fragment>
  );
};

export default RequestACallbackForm;

const Loading = () => (
  <Absolute>
    <Oval stroke="#000" />
  </Absolute>
);

const Absolute = styled.div`
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  small {
    color: #819ba6;
    font-size: 12px;
    white-space: break-spaces;
    margin-bottom: 15px;
    line-height: 16px;
    text-align: center;
    a {
      color: #007bff;
      text-decoration: none;
      background-color: transparent;
    }
  }
`;

const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  span {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }
  input {
    display: block;
    height: 32px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background-color: #000;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
  select {
    width: inherit;
    display: block;
    height: 46px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background-color: #000;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
  textarea {
    display: block;
    min-height: 8s0px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background-color: #000;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    resize: vertical;
  }
  &.mr-5 {
    margin-right: 5px;
  }
  &.ml-5 {
    margin-left: 5px;
  }
`;

const Button = styled.button`
  display: inline-block;
  font-weight: 600;
  color: #212529;
  margin-top: 20px;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1.2rem;
  line-height: 2;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  color: #000;
  background-color: #fdc00a;
  border-color: #fdc00a;
  font-family: 'Poppins', sans-serif;
  &:not(:disabled) {
    cursor: pointer;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  ${FormGroup} {
    &.ml-5 {
      margin-left: 0;
    }
  }
  @media screen and (min-width: 540px) {
    flex-direction: row;
    ${FormGroup} {
      &.ml-5 {
        margin-left: 5px;
      }
    }
  }
`;

const Body = styled.div`
  padding: 30px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  p {
    font-size: 1.5rem;
    margin: 0;
    font-weight: 500;
    line-height: 2rem;
    margin-bottom: 25px;
    color: #fdc00a;
  }
`;

const ValidationSchema = Yup.object().shape({
  FirstName: Yup.string()
    .required('Please enter the required field')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
    .test('len', 'Must be less than 128 characters', (val) =>
      val ? val?.length <= 128 : true
    ),
  LastName: Yup.string()
    .required('Please enter the required field')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  Email: Yup.string()
    .email('Invalid email')
    .required('Please enter the required field'),
  Phone: Yup.string()
    .required('Please enter the required field')
    .test('len', 'Must be exactly 10 numbers', (val) => val?.length === 10),
  Message: Yup.string()
    .matches(
      /^[aA-zZ0-9.,()@#%&!\s]+$/,
      'Only A-Z a-z 0-9 .,()@#%&! are allowed'
    )
    .test(
      'len',
      'The field Message must be a string with a maximum length of 256.',
      (val) => (val ? val?.length <= 256 : true)
    )
});
