import styled from 'styled-components';
import { Link } from 'react-router-dom';
import gtag from '../gtag';
import logo from '../logo.png';
import { MaxContainerHeader, Logo, ButtonLink, FlexGrow } from '../styles';
// import RequestACallbackOnHeader from "./RequestACallback/RequestACallbackOnHeader";

export const Header = () => (
  <StyledHeader>
    <Nav>
      <Link to="/">
        <Logo src={logo} alt="logo" className="logo" />
      </Link>
      <FlexGrow />
      {/* <RequestACallbackOnHeader /> */}
      <LoginLink
        href="https://social.stockedge.com/login"
        onClick={() => {
          gtag('event', 'login');
        }}
      >
        Login
      </LoginLink>
      <SubscribeLink
        href="/#subscribe"
        onClick={() => {
          gtag('event', 'subscribe');
        }}
      >
        Subscribe
      </SubscribeLink>
      <SubscribeLink
        href="/#analysts"
        onClick={() => {
          gtag('event', 'subscribe');
        }}
        style={{ display: 'none' }}
      >
        Subscribe
      </SubscribeLink>
      <SubscribeLink
        href="/#experts"
        onClick={() => {
          gtag('event', 'subscribe');
        }}
        style={{ display: 'none' }}
      >
        Subscribe
      </SubscribeLink>
      <a href="/#black" style={{ display: 'none' }}>
        black
      </a>
    </Nav>
  </StyledHeader>
);

const LoginLink = styled(ButtonLink).attrs({
  target: '_blank'
})`
  text-transform: none;
  font-size: 1rem;
  width: 133px;
  height: 50px;
  border-radius: 5px;
  &:hover,
  &:focus {
    background-color: #1d458a;
  }
  @media screen and (max-width: 530px) {
    font-size: 0.8rem;
    width: 90px;
    height: 35px;
  }
`;

const SubscribeLink = styled(ButtonLink)`
  text-transform: none;
  font-size: 1rem;
  width: 133px;
  height: 50px;
  border: none;
  border-radius: 5px;
  color: #fff;
  background-color: #ee9949;
  padding: 9px;

  @media screen and (max-width: 530px) {
    font-size: 0.8rem;
    width: 90px;
    height: 35px;
  }
`;

const StyledHeader = styled.header`
  --padding: 10px;
  // position: fixed;
  // top: 0;
  z-index: 2;
  width: calc(100% - 20px);
  height: var(--header-height);
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.25);

  ${Logo} {
    height: 72px;
    margin-bottom: -20px;
    z-index: 1;
    position: relative;
  }

  ${ButtonLink} {
  }

  @media screen and (min-width: 640px) {
    --header-height: 72px;
    width: calc(100vw - 38px);
    z-index: 9999;

    ${Logo} {
      height: 100px;
      margin-bottom: -50px;
    }
  }
`;

const Nav = styled(MaxContainerHeader)`
  display: flex;
  align-items: center;
`;

export const DummyHeader = styled.div`
  width: calc(100% - 20px);
  height: var(--header-height);
  padding: 10px;
`;
