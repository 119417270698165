import { Button, styled } from '@mui/material';
import logo from '../../logo.png';
import { useRequestACallback } from '../../components/RequestACallback/useRequestACallback';

const AskAnyQuestionHeader = () => {
  const { openModal } = useRequestACallback() as any;
  return (
    <Header>
      <Nav>
        <Logo src={logo} alt="logo" />
        <ButtonWraper>
          <CallbackButon onClick={openModal}>Request a Callback</CallbackButon>
          <LoginButon
            variant="outlined"
            href="https://social.stockedge.com"
            target="_blank"
          >
            Login
          </LoginButon>
          <SubscribeButon
            href="https://stockedge.com/club#subscription-price"
            target="_blank"
          >
            Subscribe
          </SubscribeButon>
        </ButtonWraper>
      </Nav>
    </Header>
  );
};

export default AskAnyQuestionHeader;

const Header = styled('header')({
  width: '100%',
  height: '80px',
  background: 'rgba(0, 0, 0, 0.30)',
  backdropFilter: 'blur(10px)',
  position: 'fixed',
  top: 0,
  zIndex: 9999
});

const Nav = styled('nav')({
  width: 'min(1360px, 100%)',
  height: '80px',
  margin: '0 auto',
  display: 'flex'
});

const Logo = styled('img')({
  width: '70px',
  height: '92.676px',
  marginTop: '14px',
  marginLeft: '12px',
  '@media (min-width: 1360px)': {
    marginLeft: '0'
  }
});

const ButtonWraper = styled('div')({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center'
});

const CallbackButon = styled(Button)({
  width: '210px',
  height: '45px',
  background: '#FDC00A',
  color: '#000',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: 'normal',
  textTransform: 'none',
  '&:hover': {
    background: '#FDC00A'
  },
  display: 'none',
  '@media (min-width: 540px)': {
    display: 'flex'
  }
});

const LoginButon = styled(Button)<any>({
  width: '121px',
  height: '45px',
  color: '#989898',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: 'normal',
  textTransform: 'none',
  marginLeft: '15px',
  borderColor: '#ffffff3d',
  '&:hover': {
    borderColor: '#ffffff3d'
  }
});

const SubscribeButon = styled(Button)<any>({
  width: '135px',
  height: '45px',
  background: '#FDC00A',
  color: '#000',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: 'normal',
  textTransform: 'none',
  marginLeft: '15px',
  '&:hover': {
    background: '#FDC00A'
  },
  marginRight: '12px',
  '@media (min-width: 1360px)': {
    marginRight: '0'
  }
});
