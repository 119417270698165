import styled from 'styled-components';
import { MaxContainer, Title, Paragraph, Background1 } from '../styles';
import { DummyHeader } from '../components/Header';
import FreeDemoForm from '../components/FreeDemoForm';
import bg4 from '../assets/bg/4.png';

const BookFreeDemo = () => (
  <Background1>
    <DummyHeader />
    <Container className="container">
      <div>
        <Title className="desktop">
          Join India&apos;s {'\n'}
          <mark>Top stock</mark> {'\n'}
          Market {'\n'}
          <mark>Community</mark>
        </Title>
        <Title className="mobile">
          Join India&apos;s {'\n'}
          <mark>Top stock</mark> Market {'\n'}
          <mark>Community</mark>
        </Title>
        <Paragraph>
          A unique and comprehensive plan that combines ready made analysis,
          community discussion, daily learning and idea sharing around stock
          investing and trading.
        </Paragraph>
      </div>
      <FormBackground>
        <FreeDemoForm />
      </FormBackground>
    </Container>
  </Background1>
);

export default BookFreeDemo;

const FormBackground = styled.div`
  background-image: url(${bg4});
  display: block;
`;

const Container = styled(MaxContainer)`
  padding: 0 10px;
  width: calc(100% - 20px);

  ${Title} {
    text-align: center;

    &.desktop {
      display: none;
    }
    &.mobile {
      display: block;
    }
  }

  ${Paragraph} {
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 0 15px;
    width: calc(100% - 30px);
    margin-top: 5rem;
    ${Title} {
      text-align: left;
      font-size: 2rem;
      line-height: 2.5rem;
      &.desktop {
        display: block;
      }
      &.mobile {
        display: none;
      }
    }

    ${Paragraph} {
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: left;
    }
  }

  @media screen and (min-width: 1024px) {
    ${Title} {
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }
`;
