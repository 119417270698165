import styled from 'styled-components';
import gtag from '../gtag';
import { MaxContainer } from '../styles';

const Footer = (props: any) => (
  <footer {...props}>
    <Container>
      <div>
        <p className="text-left">
          SEBI Registration Investment Adviser: INA000017781
        </p>
        <p className="text-left">
          SEBI Registration Research Analyst: INH300007493{' '}
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <p className="text-center">
          <a
            href="https://social.stockedge.com/privacy"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              gtag('event', 'privacy_policy');
            }}
          >
            Privacy Policy{' '}
          </a>
          |
          <a
            href="https://social.stockedge.com/terms"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              gtag('event', 'terms_and_conditions');
            }}
          >
            Terms & Conditions
          </a>
          |
          <a
            href="mailto:support@stockedge.com"
            onClick={() => {
              gtag('event', 'support');
            }}
          >
            Support
          </a>
        </p>
        <p className="text-center">
          <a
            href="https://stockedge.com/regulatorydocumentsandpolicies"
            target="_blank"
            rel="noreferrer"
          >
            Regulatory Details
          </a>
        </p>
        <p className="text-center no-mobile">
          Copyright {new Date().getFullYear()} © Kredent InfoEdge Pvt. Ltd.
        </p>
      </div>
      <div>
        <p className="text-right">
          Call Us:{' '}
          <a
            href="tel:+919830994463"
            onClick={() => {
              gtag('event', 'call_us');
            }}
          >
            +919830994463
          </a>{' '}
          |{' '}
          <a
            href="tel:+916289906895"
            onClick={() => {
              gtag('event', 'call_us');
            }}
          >
            +916289906895
          </a>
        </p>
        <p className="text-right">
          Email Us:{' '}
          <a
            href="mailto:info@stockedge.com"
            onClick={() => {
              gtag('event', 'email_us');
            }}
          >
            info@stockedge.com
          </a>
        </p>
        <p className="text-center mobile">
          Copyright {new Date().getFullYear()} © Kredent InfoEdge Pvt. Ltd.
        </p>
      </div>
    </Container>
  </footer>
);

export default styled(Footer)`
  padding: 15px 10px;
  background-color: #000000;
  color: #bbbbbb;
  width: calc(100% - 20px);
  margin: auto;
  margin-bottom: 54px;
  @media (min-width: 540px) {
    margin: 0;
  }
`;

const Container = styled(MaxContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  div {
    margin: 8px 0 8px;
  }
  p {
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1rem;
    margin: 0;
    margin-bottom: 2px;
    a {
      margin: 0 5px;
      text-decoration: none;
      color: #bbbbbb;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .no-mobile {
    display: none;
  }
  .mobile,
  .no-mobile {
    margin-top: 2px;
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
    .no-mobile {
      display: block;
    }
    .mobile {
      display: none;
    }
    p {
      margin: 0;
      margin-bottom: 2px;
    }
  }
`;
