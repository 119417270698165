import styled from 'styled-components';
import TechnoFundaSummit from './components/TechnoFundaSummit';
import { Background4, MaxContainer } from '../../styles';

const BudgetDecoded = () => {
  return (
    <Background>
      <Container>
        <Display>
          <TechnoFundaSummit />
        </Display>
      </Container>
    </Background>
  );
};

export default BudgetDecoded;

const Display = styled.div`
  @media (min-width: 960px) {
    display: flex;
    margin: 50px auto;
  }
`;

const Container = styled(MaxContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 0 10px 0;
  @media (min-width: 640px) {
    padding: 50px 0 50px 0;
  }
`;

const Background = styled(Background4)`
  min-height: auto;
`;
