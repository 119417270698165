import { Fragment, Suspense, lazy } from 'react';
import AskMeAnything from './AskMeAnything';
import AskAnyQuestionHeader from './AskAnyQuestionHeader';
import FixedRequestACallbackOnHeader from '../../components/RequestACallback/FixedRequestACallbackOnHeader';
const HearIt = lazy(() => import('./HearIt'));
const EventItinerary = lazy(() => import('./EventItinerary'));
const Faq = lazy(() => import('./Faq'));
const WhatIsStockEdgeClub = lazy(() => import('./WhyYouAttened'));

const SectorExpert = () => {
  return (
    <Fragment>
      <AskAnyQuestionHeader />
      <AskMeAnything />
      <Suspense fallback={<Fragment />}>
        <EventItinerary />
      </Suspense>
      <Suspense fallback={<Fragment />}>
        <WhatIsStockEdgeClub />
      </Suspense>
      <Suspense fallback={<Fragment />}>
        <HearIt />
      </Suspense>
      <Suspense fallback={<Fragment />}>
        <Faq />
      </Suspense>
      <FixedRequestACallbackOnHeader />
    </Fragment>
  );
};

export default SectorExpert;
