import { Fragment, Suspense, lazy } from 'react';
import BookFreeDemo from '../sections/BookFreeDemo';

const LearnConnect = lazy(() => import('../sections/LearnConnect'));
const StockEdgeClub = lazy(() => import('../sections/StockEdgeClub'));
const Exlusive = lazy(() => import('../sections/Exclusive'));
const ClubMemberThoughts = lazy(() => import('../sections/ClubMemberThoughts'));
const Footer = lazy(() => import('../components/Footer'));

const LeadGeneration = () => (
  <Fragment>
    <BookFreeDemo />
    <Suspense fallback={<Fragment />}>
      <StockEdgeClub />
    </Suspense>
    <Suspense fallback={<Fragment />}>
      <Exlusive />
    </Suspense>
    <Suspense fallback={<Fragment />}>
      <ClubMemberThoughts />
    </Suspense>
    <Suspense fallback={<Fragment />}>
      <LearnConnect />
    </Suspense>
    <Suspense fallback={<Fragment />}>
      <Footer />
    </Suspense>
  </Fragment>
);

export default LeadGeneration;
