import { Routes, Route, Navigate } from 'react-router-dom';
import { MainLayout } from './layouts/Main';
import Home from './pages/Home';
import LeadGeneration from './pages/LeadGeneration';
import ComparePlans from './pages/ComparePlans';
import SectorExpert from './pages/sector-expert';
import Budget from './pages/budget';
// import RealTimeMarket from './pages/real-time-market';
import AskMeAnything from './pages/ask-me-anything';

export const Routers = () => (
  <Routes>
    <Route
      index
      element={
        <MainLayout>
          <Home />
        </MainLayout>
      }
    />
    <Route
      path="join"
      element={
        <MainLayout>
          <LeadGeneration />
        </MainLayout>
      }
    />
    <Route
      path="compare-plans"
      element={
        <MainLayout>
          <ComparePlans />
        </MainLayout>
      }
    />
    <Route
      path="sector-expert"
      element={
        <MainLayout>
          <SectorExpert />
        </MainLayout>
      }
    />
    {/* <Route
      path="Ask-Me-Anything"
      element={
        <MainLayout>
          <RealTimeMarket />
        </MainLayout>
      }
    /> */}
    <Route path="Ask-Me-Anything" element={<AskMeAnything />} />
    <Route path="demo" element={<Navigate to="/join" replace />} />
    <Route path="join-club" element={<Navigate to="/join" replace />} />
    <Route
      path="budget"
      element={
        <MainLayout>
          <Budget />
        </MainLayout>
      }
    />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
);
