import { useEffect } from 'react';
import { Routers } from './Routers';
import RequestACallbackProvider from 'components/RequestACallback/RequestACallbackProvider';
import { ConfirmProvider } from 'material-ui-confirm';
const App = () => {
  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element)
        element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, []);

  return (
    <ConfirmProvider>
      <RequestACallbackProvider>
        <Routers />
      </RequestACallbackProvider>
    </ConfirmProvider>
  );
};

export default App;
