import { useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../constants";



const areaDataCache = {};

export const getCountries = async () => {
  // if cache doesn't contain data
  if (!areaDataCache["countries"]) {
    // load data and add it to cache
    const { data } = await axios.get(`${API_BASE_URL}/GetCountries`);
    areaDataCache["countries"] = data
  }
  // cached data
  return areaDataCache["countries"];
};

export const useGetCountries = () => {
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        getCountries().then(setCountries);
    }, [setCountries]);

    return countries;
}