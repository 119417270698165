import { Formik, Form, ErrorMessage, Field } from 'formik';
import styled from 'styled-components';
import axios from 'axios';
import * as Yup from 'yup';
import { load } from 'recaptcha-v3';
import { Oval } from 'react-loading-icons';
import { RECAPTHA_SITE_KEY, API_BASE_URL } from '../../constants';
import { useGetCountries } from '../../hooks';
import { useConfirm } from 'material-ui-confirm';
import { Typography } from '@mui/material';

const RequestACallbackForm = ({ setModal }: any) => {
  const countries = useGetCountries();
  const confirm = useConfirm();
  return (
    <Body>
      <p>
        Kindly fill in your details in the form below, our representative will
        contact you shortly.
      </p>
      <Formik
        enableReinitialize
        initialValues={{
          FirstName: '',
          LastName: '',
          Email: '',
          PhoneDialInCode: '+91',
          Phone: '',
          Message: ''
        }}
        onSubmit={async (values, action) => {
          try {
            const recaptcha = await load(RECAPTHA_SITE_KEY);
            const RecaptchaToken = await recaptcha.execute('submit');
            if (window.location.pathname.includes('summit')) {
              Object.assign(values, { Source: 'techno-funda-summit' });
            }
            if (window.location.pathname.includes('sector-expert')) {
              Object.assign(values, { Source: 'sector-expert' });
            }
            await axios.post(`${API_BASE_URL}/validateCaptcha`, {
              ...values,
              RecaptchaToken
            });
            action.resetForm();
            confirm({
              title: 'Request submitted successfully.',
              description: (
                <Typography>
                  You can also call <a href="tel:9830994463">9830994463</a> if
                  you need any immediate assistance.
                </Typography>
              ),
              hideCancelButton: true,
              dialogProps: {
                PaperProps: {
                  sx: {
                    maxWidth: 400
                  }
                }
              }
            });
            setModal('MESSAGE');
          } catch (e) {
            console.error(e);
          } finally {
            action.setSubmitting(false);
          }
        }}
        validationSchema={ValidationSchema}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          values,
          isSubmitting
        }) => (
          <Form onSubmit={handleSubmit} noValidate>
            {isSubmitting && <Loading />}
            <Flex>
              <FormGroup className="mr-5">
                <Label>First Name*</Label>
                <Field name="FirstName" />
                <ErrorMessage name="FirstName" component="span" />
              </FormGroup>
              <FormGroup className="ml-5">
                <Label>Last Name*</Label>
                <Field name="LastName" />
                <ErrorMessage name="LastName" component="span" />
              </FormGroup>
            </Flex>
            <Flex>
              <FormGroup>
                <Label>Email*</Label>
                <Field name="Email" />
                <ErrorMessage name="Email" component="span" />
              </FormGroup>
            </Flex>
            <Flex>
              <FormGroup className="mr-5">
                <Label>Country</Label>
                <select
                  name="PhoneDialInCode"
                  value={values.PhoneDialInCode}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  {countries?.map((item: any) => (
                    <option key={item.ID} value={item.DialInCode}>
                      {item.Name} ({item.DialInCode})
                    </option>
                  ))}
                </select>
              </FormGroup>
              <FormGroup className="ml-5">
                <Label>Phone*</Label>
                <input
                  name="Phone"
                  value={values.Phone}
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.target;
                    setFieldValue('Phone', value.replace(/\D/g, ''));
                  }}
                  maxLength={10}
                  minLength={10}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="Phone" component="span" />
              </FormGroup>
            </Flex>
            <Flex>
              <FormGroup>
                <Label>Type your question:</Label>
                <textarea
                  name="Message"
                  value={values.Message}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  rows={3}
                />
                <ErrorMessage name="Message" component="span" />
              </FormGroup>
            </Flex>
            <Center>
              <small>
                This site is protected by reCAPTCHA and the Google {'\n'}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a
                  href="https://policies.google.com/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>{' '}
                apply.
              </small>
            </Center>
            <Center className="fixed-at-bottom">
              <Button type="submit">I want to know more</Button>
            </Center>
          </Form>
        )}
      </Formik>
    </Body>
  );
};

export default RequestACallbackForm;

const Loading = () => (
  <Absolute>
    <Oval stroke="#000" />
  </Absolute>
);

const Absolute = styled.div`
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  small {
    color: #819ba6;
    font-size: 12px;
    white-space: break-spaces;
    margin-bottom: 15px;
    line-height: 16px;
    text-align: center;
    a {
      color: #007bff;
      text-decoration: none;
      background-color: transparent;
    }
  }
`;

const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 6px;
  display: flex;
  flex-direction: column;
  span {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }
  input {
    display: block;
    height: 32px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
  select {
    width: inherit;
    display: block;
    height: 46px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
  textarea {
    display: block;
    min-height: 8s0px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    resize: vertical;
  }
  &.mr-5 {
    margin-right: 5px;
  }
  &.ml-5 {
    margin-left: 5px;
  }
`;

const Label = styled.label`
  color: #819ba6;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0.36rem;
`;

const Button = styled.button`
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  font-size: 20px;
  padding: 10px 20px;
  &:not(:disabled) {
    cursor: pointer;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  ${FormGroup} {
    &.ml-5 {
      margin-left: 0;
    }
  }
  @media screen and (min-width: 540px) {
    flex-direction: row;
    ${FormGroup} {
      &.ml-5 {
        margin-left: 5px;
      }
    }
  }
`;

const Body = styled.div`
  // width: min-content;
  padding: 16px;
  position: relative;
  p {
    font-size: 0.8rem;
    margin: 0;
    line-height: 1.25rem;
    margin-bottom: 5px;
    color: #819ba6;
  }
`;

const ValidationSchema = Yup.object().shape({
  FirstName: Yup.string()
    .required('Please enter the required field')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
    .test('len', 'Must be less than 128 characters', (val) =>
      val ? val?.length <= 128 : true
    ),
  LastName: Yup.string()
    .required('Please enter the required field')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  Email: Yup.string()
    .email('Invalid email')
    .required('Please enter the required field'),
  Phone: Yup.string()
    .required('Please enter the required field')
    .test('len', 'Must be exactly 10 numbers', (val) => val?.length === 10),
  Message: Yup.string()
    .matches(
      /^[aA-zZ0-9.,()@#%&!\s]+$/,
      'Only A-Z a-z 0-9 .,()@#%&! are allowed'
    )
    .test(
      'len',
      'The field Message must be a string with a maximum length of 256.',
      (val) => (val ? val?.length <= 256 : true)
    )
});
