import styled from 'styled-components';
import imgBudget from './budget-image.png';

const TechnoFundaSummit = () => (
  <Container>
    <Title>Budget Decoded</Title>
    <SubTitle>Live Markets & Discussion with Market Experts</SubTitle>
    <Paragraph>1 Day I 12 Experts I Infinite Learning</Paragraph>
    <Image src={imgBudget} alt="" />
    <OvalBox>Date: 1st February, Wednesday | Time: 9 am to 4 pm</OvalBox>
    <Paragraph>
      Exclusive for StockEdge Club Members{'\n'}
      Join StockEdge club for <mark>1999/-</mark> to get access to this
      exclusive event.
    </Paragraph>
    <JoinButton
      rel="noreferrer"
      href="https://stockedge.com/Plans/Buy?id=32&utm_source=stockedgeclub.com&utm_medium=website&utm_campaign=budget-homepage-banner"
      target="_blank"
    >
      JOIN STOCKEDGE CLUB
    </JoinButton>
    <Link
      rel="noreferrer"
      href="https://stockedge.com/club?utm_source=stockedgeclub.com&utm_medium=website&utm_campaign=budget-homepage-banner"
      target="_blank"
    >
      Know more about StockEdge Club
    </Link>
  </Container>
);

export default TechnoFundaSummit;

const Image = styled.img`
  width: 100%;
`;

const OvalBox = styled.div`
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #363636;
  box-shadow: 0px 0px 50px rgba(52, 52, 52, 0.3);
  color: #ffffff;
  white-space: pre-line;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  border-radius: 50px;
  padding: 10px 50px;
  margin-bottom: 16px;
  margin-top: 16px;
  text-align: center;
  @media (min-width: 360px) {
    font-size: 14px;
    line-height: 25px;
  }
  @media (min-width: 425px) {
    font-size: 16px;
    line-height: 30px;
  }
  @media (min-width: 640px) {
    font-size: 20px;
    line-height: 36px;
  }
`;

const Title = styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  white-space: pre-line;
  text-transform: uppercase;
  text-align: center;
  @media (min-width: 360px) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (min-width: 425px) {
    font-size: 40px;
    line-height: 50px;
  }

  @media (min-width: 640px) {
    font-size: 50px;
    line-height: 75px;
  }
`;

const SubTitle = styled.h4`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
  color: #fdc00a;
  margin: 4px auto;
  @media (min-width: 360px) {
    font-size: 20px;
    line-height: 25px;
  }

  @media (min-width: 425px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (min-width: 640px) {
    font-size: 28px;
    line-height: 36px;
  }
`;

const Paragraph = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  white-space: pre-line;
  color: #ffffff;
  margin: 4px auto;
  mark {
    background-color: transparent;
    color: #fdc00a;
  }

  @media (min-width: 360px) {
    font-size: 16px;
    line-height: 25px;
  }

  @media (min-width: 425px) {
    font-size: 20px;
    line-height: 30px;
  }

  @media (min-width: 640px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const Time = styled.h4`
  color: #fdc00a;
  font-size: 1rem;
  text-align: center;
  font-weight: 500;
  line-height: 1.25rem;
  text-transform: capitalize;
  margin-top: 10px;
`;

const JoinButton = styled.a`
  max-width: 300px;
  width: 100%;
  height: 50px;
  background: #fdc00a;
  border-radius: 50px;
  font-size: 0.875rem;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #000000;
  margin: 20px 0 10px;
`;

const Link = styled.a`
  color: #ffff;
  font-size: 0.75rem;
  line-height: 1rem;
  margin: auto;
  margin-top: 16px;
`;

const Container = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 960px) {
    display: inline-flex;
    margin: 0 0 0 20px;
    ${Title} {
      font-size: 2.5rem;
      line-height: 3.2rem;
      margin: 0;
    }
    ${SubTitle} {
      text-align: left;
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
    ${Paragraph} {
      font-size: 0.875rem;
      line-height: 1.5rem;
      span {
        font-size: 1.25rem;
      }
    }
    ${Time} {
      margin: 10px 0 0 0;
    }
  }
  @media (min-width: 1025px) {
    ${Title} {
      font-size: 2.65rem;
      line-height: 3.5rem;
      white-space: nowrap;
      margin: 0;
    }
    ${SubTitle} {
      text-align: left;
      font-size: 1.5rem;
      line-height: 2rem;
    }
    ${Paragraph} {
      font-size: 1rem;
      line-height: 1.5rem;
      span {
        font-size: 1.75rem;
      }
    }
    ${JoinButton} {
      font-size: 1rem;
      height: 44px;
    }
  }
`;
