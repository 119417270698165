import { Fragment } from "react";
import { Header } from "../components/Header";
import FixedRequestACallbackOnHeader from "../components/RequestACallback/FixedRequestACallbackOnHeader";

export const MainLayout = ({children}) => (
    <Fragment>
        <Header />
        {children}
        <FixedRequestACallbackOnHeader/>
    </Fragment>
)