import { Fragment, Suspense, lazy } from 'react';
import BecomeStockEdgeExpert from './BecomeStockEdgeExpert';

const WhatJoinUs = lazy(() => import('./WhatJoinUs'));
const SelectionCriteria = lazy(() => import('./SelectionCriteria'));
const Exlusive = lazy(() => import('./Exclusive'));
const Faq = lazy(() => import('./Faq'));
const Footer = lazy(() => import('../../components/Footer'));

const SectorExpert = () => {
  return (
    <Fragment>
      <BecomeStockEdgeExpert />
      <Suspense fallback={<Fragment />}>
        <WhatJoinUs />
      </Suspense>
      <Suspense fallback={<Fragment />}>
        <Exlusive />
      </Suspense>
      <Suspense fallback={<Fragment />}>
        <SelectionCriteria />
      </Suspense>
      <Suspense fallback={<Fragment />}>
        <Faq />
      </Suspense>
      <Suspense fallback={<Fragment />}>
        <Footer />
      </Suspense>
    </Fragment>
  );
};

export default SectorExpert;
