import { Fragment, useState } from 'react';
import styled from 'styled-components';
import RequestACallbackModal from './RequestACallbackModal';

const RequestACallback = () => {
  const [modal, setModal] = useState<string | undefined>(undefined);
  const openModal = () => setModal('FORM');

  return (
    <Fragment>
      <FixedDiv>
        <RButton onClick={openModal}>Request A Callback</RButton>
      </FixedDiv>
      <RequestACallbackModal modal={modal} setModal={setModal} />
    </Fragment>
  );
};

export default RequestACallback;

const FixedDiv = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #000;
  display: flex;
  justify-content: center;
  padding: 10px;
  @media (min-width: 540px) {
    display: none;
  }
`;

const RButton = styled.button`
  font-family: Poppins, sans-serif;
  border: none;

  border-radius: 5px;
  color: #fff;
  background-color: #ee9949;
  font-size: 1rem;

  text-transform: uppercase;
  text-decoration: none;
  box-sizing: border-box;
  padding: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  line-height: 1rem;
  padding: 9px;
  max-width: 240px;
  width: 100%;
`;
