import ss, { createGlobalStyle } from 'styled-components';
import bg1 from './assets/bg/1.png';
import bg2 from './assets/bg/2.png';
import bg3 from './assets/bg/3.png';
import bg4 from './assets/bg/4.png';
import bg5 from './assets/bg/5.png';
import { Typography, TypographyProps, styled } from '@mui/material';

export const GlobalStyle = createGlobalStyle`
    :root {
        --swiper-pagination-bullet-inactive-color: #fff;
        --max-width: 1110px;
        --color-gold: #fdc00a;
        --color-black: #000000;
        --title-fs: 50px;
        --title-lt: 64px;
        --desc-fs: 20px;    
        --desc-lt: 32px;
        --bg: #000000;
        --header-height: 64px;
    }

    body {
      margin: 0;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: normal;
      background-color: #000;
    }

    .swiper-button-prev {
        background-image: url(./prev.png);
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
    }

    .swiper-button-prev::after {
        display: none;
    }

    .swiper-button-next {
        background-image: url(./next.png);
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
    }

    .swiper-button-next::after {
        display: none;
    }

    .flip-countdown {
      text-align: left;
      margin-top: 24px;
      .flip-countdown-card {
        background: #212325;
        border: 1px solid rgba(255, 255, 255, 0.2);
      }
      
    }
    .card__top{
      border: none !important;
    }
    .card__bottom {
      border-radius: 0 !important;
      background-color:#222 !important;
    }
    .flip-countdown-piece {
      display: inline-flex !important;
      flex-direction: column;
      align-items: center;
    }

    .flip-countdown-card-sec {
      font-size: 50px !important;
      font-weight: bold;
    }

    @media (min-width: 1024px) {
      .flip-countdown-card-sec{
        font-size: 64px !important;
        font-weight: bold;
      }
    }

    @media (max-width: 360px) {
      .flip-countdown-card-sec{
        font-size: 40px !important;
        font-weight: bold;
      }
    }


.flip-countdown-title {
    font-size: 20px !important;
    color: #fff !important;
    margin-top: 10px;
}
    @media screen and (min-width: 640px) {
        :root {
            --header-height: 72px;
        }
    }
    @media screen and (max-width: 539px){
      .rc-dialog-wrap {
        display: block;
      }
      div.rc-dialog {
        position: fixed;
        margin:0;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
      }
      div.rc-dialog-body {
        overflow-y: auto;
        height: calc(100vh - 50px);
        & > div {
          padding-bottom: 50px;
        }
      }
      div.fixed-at-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        padding: 6px;
      }
    }
`;

export const Background = styled('div')`
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
`;

export const Background1 = styled(Background)`
  background-image: url(${bg1});
  width: 100%;
  min-height: min(768px, 100vh);
  border-bottom: 1px solid #363636;
  @media screen and (max-width: 768px) {
    .container {
      margin-top: 3rem;
    }
  }
`;
export const Background2 = styled(Background)`
  background-image: url(${bg2});
  width: 100%;
  min-height: min(768px, 100vh);
  border-bottom: 1px solid #363636;
  @media screen and (max-width: 768px) {
    .container {
      margin-top: 3rem;
    }
  }
`;
export const Background3 = styled(Background)`
  background-image: url(${bg3});
  width: 100%;
  min-height: min(768px, 100vh);
  border-bottom: 1px solid #363636;
  @media screen and (max-width: 768px) {
    .container {
      margin-top: 3rem;
    }
  }
`;

export const Background4 = styled(Background)`
  background-image: url(${bg1}), url(${bg4});
  background-position: center, center;
  background-repeat: no-repeat, no-repeat;
  width: 100%;
  min-height: min(768px, 100vh);
  border-bottom: 1px solid #363636;
  text-decoration: none;
  @media screen and (max-width: 768px) {
    .container {
      margin-top: 3rem;
    }
  }
`;

export const Background5 = styled(Background)`
  background-image: url(${bg1}), url(${bg5});
  width: 100%;
  min-height: min(768px, 100vh);
  border-bottom: 1px solid #363636;
  @media screen and (max-width: 768px) {
    .container {
      margin-top: 3rem;
    }
  }
`;
export const Background6 = styled(Background)`
  background-color: #fff;
  width: 100%;
  min-height: min(768px, 100vh);
  border-bottom: 1px solid #363636;
  @media screen and (max-width: 768px) {
    .container {
      margin-top: 3rem;
    }
  }
`;

export const DummyHeader = styled('div')`
  width: calc(100% - 20px);
  height: var(--header-height);
  padding: 10px;
`;

export const SectionWraper = styled('div')`
  height: 100%;
  width: 100%;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: green;
`;

export const FlexGrow = styled('div')`
  flex-grow: 1;
`;

export const HeaderAdjust = styled('header')`
  height: var(--header-height);
`;

export const Logo = ss('img')`
  height: 100px;
  object-fit: contain;
  object-position: center;
  box-sizing: border-box;
`;

export const ButtonLink = ss('a')`
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
  text-decoration: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  width: min(120px, 100%);
  padding: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-left: 10px;
`;

export const MaxContainer = ss.div`
  margin: 0 auto;
  width: 100%;
  height: inherit;
  max-width: var(--max-width);
`;
export const MaxContainerHeader = styled('div')`
  margin: 0 auto;
  width: 100%;
  height: inherit;
  max-width: 1400px;

  @media screen and (max-width: 1550px) {
    max-width: 1180px;
  }
`;

export const Title = ss.h1`
  text-transform: none !important;
  font-size: 1.75rem;
  line-height: 2.25rem;
  white-space: normal;
  margin: 0;
  font-weight: 800;
  text-transform: uppercase;
  color: #ffffff;

  mark {
    background-color: transparent;
    color: #ee9949;
  }
`;

export const SubTitle = ss('h1')`
  font-size: 1.5rem;
  font-weight: 300;
  color: #ffffff;
`;

export const Paragraph = ss.p`
  font-size: 0.85rem;
  line-height: 1rem;
  font-weight: 300;
  color: #bbbbbb;
`;

export const Image = ss('img')`
  width: 100%;
  object-fit: contain;
  object-position: center;
`;

export const Section = styled('section')`
  --margin-top: 0px;
  --padding-top: 0px;
  height: 100vh;
  width: calc(100vw - 20px);
  scroll-snap-align: start;
  scroll-margin-top: var(--header-height);
  padding: 0 10px;
  background-color: #000;
  overflow: hidden;

  &.section-learning-earning {
    background-image: url(${bg1});
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.section-stockedge-club {
    background-image: url(${bg2});
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.section-exclusive {
    background-image: url(${bg1});
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.section-growth-slider {
    .swiper {
      height: inherit !important;
    }

    .swiper-slide {
      height: inherit !important;
    }
  }

  &.section-community-with {
    background-image: url(${bg3});
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.section-top-analysts {
    background-image: url(${bg2});
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.section-top-markets-experts {
    background-image: url(${bg2});
  }

  &.section-club-member-thoughts {
    background-image: url(${bg2});
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.section-subscription-plans {
    background-image: url(${bg2});
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media screen and (min-width: 640px) {
    // ${Title} {
    //   font-size: 3rem;
    //   line-height: 3.5rem;
    // }

    // ${Paragraph} {
    //   font-size: 1.125rem;
    //   line-height: 1.5rem;
    // }
  }
`;

export const TitleUnderline = ss('hr')`
  width: 67px;
  text-align: left;

  margin-left: 0;
  border: 1px solid #ee9949;

  @media screen and (max-width: 520px) {
    width: 40px;
  }
`;

export const ExclusiveFeaturesTitle = ss('h1')`
  text-transform: none !important;
  font-size: 1.75rem;
  line-height: 2.25rem;
  white-space: pre-line;
  margin: 0;
  font-weight: 800;
  text-transform: uppercase;
  color: #12274b;

  mark {
    background-color: transparent;
    color: #ee9949;
  }
`;

export const Heading = styled((props: TypographyProps) => (
  <Typography variant="h1" {...props} />
))({
  width: '100%',
  fontFamily: '"Roboto", sans-serif',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '32px',
  textTransform: 'capitalize',
  whiteSpace: 'pre-line',
  marginBottom: '32px',
  '@media (min-width: 360px)': {
    fontSize: '30px',
    lineHeight: '44px'
  },
  '@media(min - width: 425px)': {
    fontSize: '32px',
    lineHeight: '45px'
  },
  '@media(min - width: 640px)': {
    fontSize: '36px',
    lineHeight: '50px'
  },
  '&.mobile': {
    justifyContent: 'center',
    display: 'flex'
  },
  '&.desktop': {
    display: 'none'
  },
  '@media (min-width: 768px)': {
    '&.mobile': {
      display: 'none'
    },
    '&.desktop': {
      display: 'flex'
    }
  },
  '@media (min-width: 960px)': {
    fontSize: '40px',
    lineHeight: '50px'
  },
  '&.underline': {
    position: 'relative',
    paddingBottom: '40px',
    marginBottom: '40px',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      width: '72px',
      borderBottom: '4px solid #EE9949',
      margin: 'auto'
    }
  }
});

export const Mark = styled('mark')({
  color: '#EE9949',
  background: 'transparent'
});
