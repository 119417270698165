import { Suspense, lazy } from 'react';
import Footer from '../components/Footer';
const PriceTable = lazy(() => import('../sections/PriceTable'));

const ComparePlans = () => (
  <Suspense fallback={<div />}>
    <PriceTable />
    <Footer />
  </Suspense>
);

export default ComparePlans;
